export const ROUTES = {
  privacyPolicy: '/privacy-policy',
  ecoSat: '/eco-sat',
  mineCam: '/mine-cam',
  hype4Explor: '/hype-4-explor',
  contactUs: '/contact-us',
  caseStudies: '/case-studies/:id',
  terraeyeApp: '/terraeye-app',
  newsletterCancel: '/newsletter-cancel',
  termsAndConditions: '/terms-and-conditions',
  termsAndConditionsDgp: '/terms-and-conditions-dgp',
  newsletterRegulations: '/newsletter-regulations',
  privacyPolicyTerraeyeApp: '/privacy-policy-terraeye-app',
  data: '/data',
  reports: '/reports',
  digitalTwin: '/digital-twin',
  digitalTwinContactUs: '/digital-twin-contact-us',
  digitalTwinPrivacyPolicy: '/digital-twin-privacy-policy',
};
